<template>
  <div class="page-404">
    <div class="error-container">
      <div class="error-content">
        <h1 class="animated-text">404</h1>
        <h2>¡Página no encontrada!</h2>
        <p>Lo sentimos, la página que estás buscando no existe.</p>
        <b-button variant="primary" @click="returnHome" class="btn"
          >Volver al Inicio</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
  methods: {
    returnHome() {
      this.$router.push({ name: "DashboardView" });
    },
  },
};
</script>

<style scoped>
.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  background: #ffffffb7;
}
.error-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 400px;
}
.error-content {
  padding: 20px;
}
h1.animated-text {
  font-size: 72px;
  color: #ff5722;
  animation: fadeIn 2s ease infinite;
  margin: 0;
}
h2 {
  font-size: 36px;
  margin: 10px 0;
  color: #333;
}
p {
  font-size: 20px;
  margin: 20px 0;
  color: #555;
}
.btn {
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
